import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { Amplify } from "aws-amplify";
console.log("Main Js", window.location.hostname);
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "dev.bankxchange.com" 
        ? "us-east-1_I0rLx26yw"
        : window.location.hostname === "test.bankxchange.com" ||  window.location.hostname === "localhost" || window.location.hostname === '192.168.1.169'
        
        ? "us-east-1_8hmKPF8Gu"
        : window.location.hostname === "uat.bankxchange.com"
        ? "us-east-1_qMHQ0Xfzl"
        : "us-east-1_TJuR48hjY",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "dev.bankxchange.com" 
        ? "5imapmjeh6kbhq1r7bqimv8397"
        : window.location.hostname === "test.bankxchange.com" ||  window.location.hostname === "localhost" || window.location.hostname === '192.168.1.169'
        ? "54uqubcs9urjqacsbjko4hg47l"
        : window.location.hostname === "uat.bankxchange.com"
        ? "1tl7di5lucp76jjv4f8eratlog"
        : "179ulqklulg7phb28lsplrgkou",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "dev.bankxchange.com" 
        ? "https://gfoyixxpxzgh3kuqf6ir4p6vlu.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "test.bankxchange.com" ||  window.location.hostname === "localhost" || window.location.hostname === '192.168.1.169'
        ? "https://kfwvxkep7jggvpxn43wk765urq.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uat.bankxchange.com"
        ? "https://bjgxq3i2bncxbg3zxjhlhqtuxq.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://ekwxm4vsaja67mpe2ouainwlzm.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
if (window.location.hostname === "test.bankxchange.com") {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-VY3P4RM7B6";
  document.head.appendChild(script);

  script.onload = () => {
    // Initialize gtag after the script has loaded
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-VY3P4RM7B6");

    // Track initial page load
    router.afterEach((to) => {
      gtag("config", "G-VY3P4RM7B6", {
        page_path: to.fullPath,
      });
    });
  };
}
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
